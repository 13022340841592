import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Functions
import { DeterminePageColor } from "../utils/determine-page-color";
import { DetermineLinkColor } from "../utils/determine-link-color";

// Context
import { HomepageInfoPanelConsumer } from "../context/homepage-info-panel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: space-between;

  position: relative;
  width: 100%;
  height: 100%;

  background-color: ${props => props.bgColor};
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: calc(100% - 19px - 65px);
  max-height: calc(100% - 19px - 15px);
  overflow: hidden;

  padding: 19px 0 0 0;

  & .image-gallery,
  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 33.333%;
  }

  @media (max-width: 700px) {
    height: calc(100% - 19px - 80px - 1em);
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;

  padding: 70px 20px 27px 20px;

  @media (max-width: 500px) {
    padding: 40px 10px 0 10px;
  }

  width: 100%;
  height: 100%;

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 100%;
  }

  & img {
    height: 100%;
    object-fit: contain;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;

  width: 100%;
  padding: 0 20px 28px 20px;
  height: fit-content;

  @media (max-width: 960px) {
    padding: 0 10px 9px 10px;
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
  }

  & .title {
    text-transform: uppercase;

    margin: 0 15px 0 0;

    & h1,
    & h2 {
      font-size: 23px;
      line-height: 30px;
      letter-spacing: 0.02em;
    }
  }

  & .subtitle {
    & > p {
      font-family: "RotationLTStd-Roman";
      font-size: 15px;
      line-height: 20px;

      margin: 0;
    }
  }

  & .link {
    text-transform: uppercase;
    font-family: "neue-haas-grotesk-text", sans-serif;
    font-size: 12px;
  }

  & a {
    &:hover {
      color: #e5e5e5;
    }
  }

  @media (max-width: 768px) {
    & .title {
      & h1,
      & h2 {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`;

const LargeText = styled.div`
  max-width: 900px;
  margin: 0 auto;

  & h2 {
    text-transform: uppercase;

    font-size: 100px;
    line-height: 100%;

    font-family: "neue-haas-grotesk-display", sans-serif;

    @media (max-width: 767px) {
      font-size: 50px;
    }
  }
`;

const ExternalLink = styled.div`
  padding-bottom: 3px;

  & a {
    line-height: 16px;
  }
  &:hover {
    color: ${props => props.linkColor};
  }

  @media (max-width: 500px) {
    margin-top: 1em;
  }
`;

class HomepageGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      allContent: [],
      activeSlide: null,
      totalSlides: 1,
      activeSlideTitle: "",
      activeSlideSubtitle: "",
      activeSlideData: "",
      pageColor: "#ffffff",
    };
  }

  componentDidMount(props) {
    const slideTotal = this.props.content.length;

    this.setState(
      {
        allContent: this.props.content,
        totalSlides: slideTotal,
      },
      () => {
        this.setState({
          activeSlideTitle: this.state.allContent[this.state.currentSlide].link
            .document.data.title.html,
          activeSlideSubtitle: this.state.allContent[this.state.currentSlide]
            .link.document.data.subtitle.html,
          pageColor: this.state.allContent[this.state.currentSlide].link
            .document.data.color,
          activeSlideData: this.state.allContent[this.state.currentSlide].link
            .document,
        });

        this.props.setPageColor(
          DeterminePageColor(
            this.state.allContent[this.state.currentSlide].link.document.data
              .color
          )
        );
      }
    );
  }

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState(
        {
          currentSlide: index,
        },
        () => {
          this.setState({
            activeSlideTitle: this.state.allContent[this.state.currentSlide]
              .link.document.data.title.html,
            activeSlideSubtitle: this.state.allContent[this.state.currentSlide]
              .link.document.data.subtitle.html,
            pageColor: this.state.allContent[this.state.currentSlide].link
              .document.data.color,
            activeSlideData: this.state.allContent[this.state.currentSlide].link
              .document,
          });
          this.props.setPageColor(
            DeterminePageColor(
              this.state.allContent[this.state.currentSlide].link.document.data
                .color
            )
          );
        }
      );
    } else {
      this.setState({
        activeSlideTitle: this.state.allContent[this.state.currentSlide].link
          .document.data.title.html,
        activeSlideSubtitle: this.state.allContent[this.state.currentSlide].link
          .document.data.subtitle.html,
        pageColor: this.state.allContent[this.state.currentSlide].link.document
          .data.color,
        activeSlideData: this.state.allContent[this.state.currentSlide].link
          .document,
      });
      this.props.setPageColor(
        DeterminePageColor(
          this.state.allContent[this.state.currentSlide].link.document.data
            .color
        )
      );
    }
  };

  render() {
    const galleryContent = this.props.content.map((content, index) => {
      if (content.link.document.type === "project") {
        return (
          <ImageContainer className="image" key={`homepage_gallery_${index}`}>
            <Link to={content.link.document.url}>
              <img
                src={content.image.fluid.srcWebp}
                srcSet={content.image.fluid.srcSetWebp}
                alt={content.image.alt}
                loading={index <= 4 ? "eager" : "lazy"}
              />
            </Link>
          </ImageContainer>
        );
      }

      if (content.link.document.type === "news_article") {
        return (
          <ImageContainer className="image" key={`homepage_gallery_${index}`}>
            <a
              href={content.link.document.data.link.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <LargeText
                dangerouslySetInnerHTML={{
                  __html: content.link.document.data.text.html,
                }}
              />
            </a>
          </ImageContainer>
        );
      }

      if (content.link.document.type === "discourse") {
        return (
          <ImageContainer className="image" key={`homepage_gallery_${index}`}>
            <Link to={content.link.document.url}>
              <img
                src={content.image.fluid.srcWebp}
                srcSet={content.image.fluid.srcSetWebp}
                alt={content.image.alt}
                loading={index <= 4 ? "eager" : "lazy"}
              />
            </Link>
          </ImageContainer>
        );
      }
    });

    return (
      <HomepageInfoPanelConsumer>
        {({ setHomepageInfoPanel }) => (
          <Container
            bgColor={DeterminePageColor(this.state.pageColor)}
            onClick={() => setHomepageInfoPanel(false)}
          >
            <GalleryContainer>
              <Carousel
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
                className="image-gallery"
                selectedItem={this.state.currentSlide}
                onChange={this.updateCurrentSlide}
                autoPlay={true}
                stopOnHover={false}
                interval={15000}
              >
                {galleryContent}
              </Carousel>
            </GalleryContainer>
            <Caption>
              <div>
                {this.state.activeSlideData.url !== undefined ? (
                  <Link to={this.state.activeSlideData.url}>
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: this.state.activeSlideTitle,
                      }}
                    />
                  </Link>
                ) : (
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: this.state.activeSlideTitle,
                    }}
                  />
                )}

                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: this.state.activeSlideSubtitle,
                  }}
                />
              </div>
              {this.state.activeSlideData.data !== undefined &&
                this.state.activeSlideData.data.link && (
                  <ExternalLink
                    className="link"
                    linkColor={DetermineLinkColor(this.state.pageColor)}
                  >
                    <a
                      href={this.state.activeSlideData.data.link.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {this.state.activeSlideData.data.link_text}
                    </a>
                  </ExternalLink>
                )}
            </Caption>
          </Container>
        )}
      </HomepageInfoPanelConsumer>
    );
  }
}

export default HomepageGallery;
