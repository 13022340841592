import React, { createContext } from "react";

const HomepageInfoPanel = createContext({
  isHomepageInfoPanelOpen: false,
  setHomepageInfoPanel: () => {},
});

export class HomepageInfoPanelProvider extends React.Component {
  setHomepageInfoPanel = newHomepageInfoPanel => {
    this.setState({ isHomepageInfoPanelOpen: newHomepageInfoPanel });
  };

  state = {
    isHomepageInfoPanelOpen: false,
    setHomepageInfoPanel: this.setHomepageInfoPanel,
  };

  render() {
    return (
      <HomepageInfoPanel.Provider value={this.state}>
        {this.props.children}
      </HomepageInfoPanel.Provider>
    );
  }
}

export const HomepageInfoPanelConsumer = HomepageInfoPanel.Consumer;
