import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

// Functions
import { DetermineLinkColor } from "../utils/determine-link-color";

const Panel = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;
  min-height: 100%;

  z-index: 100;

  background-color: ${props =>
    props.isOpen === true ? `#fff` : props.pageColor};
  border-top: 1px solid #555;

  transform: translateY(
    ${props => (props.isOpen === true ? `19px` : `calc(100% - 20px)`)}
  );
  transition: 250ms transform linear;

  @media (max-width: 960px) {
    transform: translateY(
      ${props => (props.isOpen === true ? `19px` : `100%`)}
    );
  }

  & .container {
    padding: 0 20px 18px 20px;
    position: relative;

    height: 100%;
    overflow: ${props => (props.isOpen === true ? `auto` : `none`)};

    @media (max-width: 960px) {
      padding: 10px 10px 18px 10px;
    }

    @media (max-width: 400px) {
      padding: 10px 10px 24px 10px;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & p {
      margin: 0;
    }

    & .contact-text {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      width: 100%;

      padding: 0 0 50px 0;

      @media (max-width: 960px) {
        // flex-direction: row-reverse;
        padding: 0 0 30px 0;
      }

      & .contact-text-box {
        width: 100%;

        & p {
          width: 100%;

          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
        }
      }

      & button {
        margin: 0;

        cursor: pointer;
        color: #000;

        font-family: "neue-haas-grotesk-text", sans-serif;
        font-size: 12px;
        line-height: 19px;

        letter-spacing: 0.02em;
        text-transform: uppercase;

        &:hover {
          color: ${props => props.linkColor};
        }
      }

      & .contact-text-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & p {
          @media (max-width: 960px) {
            flex-direction: column-reverse;
          }
        }

        & br {
          display: none;
        }

        & a {
          display: inline-block;
          padding-right: 17px;

          &:last-of-type {
            padding: 0;
          }

          @media (max-width: 960px) {
            display: block;

            &:nth-of-type(4) {
              margin: 1em 0 0 0;
            }

            &:last-of-type {
              padding: 0;
            }
          }
        }
      }

      @media (max-width: 960px) {
        .desktop-close {
          display: none;
        }
      }

      & p {
        font-family: "neue-haas-grotesk-text", sans-serif;
        font-size: 12px;
        line-height: 19px;

        letter-spacing: 0.02em;
        text-transform: uppercase;
      }

      & a {
        &:hover {
          color: ${props => props.linkColor};
        }
      }
    }
  }
`;

export const Information = ({ pageColor }) => {
  const data = useStaticQuery(graphql`
    {
      prismicContact {
        data {
          contact_text {
            html
          }
          social_media_links {
            html
          }
        }
      }
    }
  `);

  return (
    <Panel pageColor={pageColor} linkColor={DetermineLinkColor(pageColor)}>
      <div className="container">
        <div className="contact-text">
          <div
            className="contact-text-box"
            dangerouslySetInnerHTML={{
              __html: data.prismicContact.data.contact_text.html,
            }}
          />
        </div>
      </div>
    </Panel>
  );
};
