import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import HomepageGallery from "../components/images/homepage-gallery";
import { Information } from "../components/homepage/information";

const IndexContent = ({ data, setPageColor, pageColor, setPageType }) => {
  useEffect(() => {
    setPageColor(`White`);
    setPageType("home");
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <Helmet title="PUP ARCHITECTS" />
      <Div100vh style={{ height: `calc(100rvh - 20px)` }}>
        <HomepageGallery
          content={data.prismicHomepage.data.item.filter(
            content => content.link.document !== null
          )}
          setPageColor={setPageColor}
        />
        <Information pageColor={pageColor} />
      </Div100vh>
    </>
  );
};

const Index = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PageColorConsumer>
        {({ setPageColor, pageColor }) => (
          <IndexContent
            setPageColor={setPageColor}
            pageColor={pageColor}
            setPageType={setPageType}
            data={data}
          />
        )}
      </PageColorConsumer>
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    prismicHomepage {
      data {
        item {
          image {
            fluid {
              srcSetWebp
              srcWebp
            }
          }
          link {
            document {
              ... on PrismicProject {
                uid
                url
                prismicId
                type
                data {
                  title {
                    html
                  }
                  subtitle {
                    html
                  }
                  color
                }
              }
              ... on PrismicNewsArticle {
                prismicId
                type
                data {
                  color
                  link {
                    url
                  }
                  link_text
                  subtitle {
                    html
                  }
                  text {
                    html
                  }
                  title {
                    html
                  }
                }
              }
              ... on PrismicDiscourse {
                id
                prismicId
                type
                data {
                  title {
                    html
                  }
                  subtitle {
                    html
                  }
                  article_title {
                    html
                  }
                }
                url
              }
            }
            type
          }
        }
      }
    }
  }
`;

export default withPreview(Index);
